"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/esnext.iterator.constructor.js");
require("core-js/modules/esnext.iterator.filter.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "trade"
  }, [_c("basic", {
    ref: "table",
    attrs: {
      "page-endpoint": _vm.endpoint,
      "page-name": "trade",
      "request-params": _vm.requestParams,
      "show-operation": (row, op) => row.push_status != 1 && row.push_status != 3 ? true : op == "delete",
      "dialog-width": _vm.editDialogWidth,
      "dialog-custom-class": "trade-dialog",
      "on-edit-row": _vm.onEditRow,
      "can-confirm-func": _vm.canConfirm,
      "format-request-data": _vm.formatRequestData,
      "format-data-list": _vm.formatDataList,
      "span-method": _vm.spanMethod,
      stripe: false,
      "support-selection": !_vm.isBusiness,
      "handle-selection-change": _vm.handleSelectionChange,
      "list-create-time": false,
      "after-handle-open": _vm.afterHandleOpen,
      "update-endpoint": _vm.updateEndpoint,
      "operation-fixed": _vm.device == "mobile" ? null : "right",
      "operation-dropdown": true,
      "operation-style": _vm.operationStyle,
      "additional-operations": [{
        type: "primary",
        title: "common.view",
        onClick: row => {
          _vm.rowDetail = row;
          _vm.detailDialogVisible = true;
        },
        show: () => true
      }, {
        type: "primary",
        title: "trade.ship.name",
        onClick: row => _vm.onOpenShipDialog(row),
        show: row => row.push_status == 1 && !_vm.isBusiness
      }, {
        type: "primary",
        title: "common.copy",
        onClick: row => _vm.onCopy(row),
        show: row => row.push_status != 3
      }, {
        type: "primary",
        title: "trade.note",
        onClick: row => _vm.onNote(row),
        show: row => !_vm.isBusiness
      }],
      "operation-width": 166,
      "search-bar-right-addition": _vm.searchBarRightButtons,
      filters: _vm.device == "mobile" ? [] : [{
        query: "erp_trade_code",
        placeholder: "trade.erp_trade_code"
      }, {
        query: "platform_trade_code",
        placeholder: "trade.platform_trade_code"
      }, {
        query: "name",
        placeholder: "trade.receiver_name"
      }, {
        query: "mobile",
        placeholder: "trade.receiver_mobile"
      }, {
        query: "address",
        placeholder: "trade.receiver_address"
      }, {
        query: "express_code",
        placeholder: "trade.express_code"
      }, {
        query: "channel_name",
        placeholder: "channel.name"
      },
      //,{
      //  query: 'product_name',
      //  placeholder: 'product.name'
      //},{
      //  dateRange: ['begin_datetime', 'end_datetime']
      //}
      {
        query: "push_status",
        placeholder: "trade.push_status_name",
        selection: [{
          value: 0,
          label: _vm.$t("trade.push_status.0")
        }, {
          value: 1,
          label: _vm.$t("trade.push_status.1")
        }, {
          value: 2,
          label: _vm.$t("trade.push_status.2")
        }]
      }, {
        query: "delivered",
        placeholder: "trade.ship.status.name",
        selection: [{
          value: 0,
          label: _vm.$t("trade.ship.status.0")
        }, {
          value: 1,
          label: _vm.$t("trade.ship.status.1")
        }]
      }, {
        dateRange: ["begin_datetime", "end_datetime"]
      }],
      "show-search-button": _vm.device != "mobile",
      "list-update-time": false,
      "label-position": "right",
      "edit-title": _vm.editNote ? _vm.$t("trade.editNote") : null,
      "skip-form-validation": _vm.editNote,
      "sort-change": _vm.sortChange
    },
    on: {
      close: _vm.handleDialogClose,
      confirm: _vm.handleConfirm
    },
    scopedSlots: _vm._u([{
      key: "addr_parser",
      fn: function (slotProp) {
        return [_c("div", {
          staticClass: "item"
        }, [_c("el-input", {
          attrs: {
            placeholder: slotProp.placeholder,
            clearable: "",
            disabled: slotProp.disabled,
            type: "textarea",
            rows: "5"
          },
          model: {
            value: _vm.addParserText,
            callback: function ($$v) {
              _vm.addParserText = $$v;
            },
            expression: "addParserText"
          }
        }), _c("el-button", {
          attrs: {
            disabled: _vm.addParserText == null || _vm.addParserText.length == 0
          },
          on: {
            click: _vm.handleParseAddr
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("trade.parse")) + " ")])], 1)];
      }
    }])
  }, [[_c("el-form-item", {
    attrs: {
      label: _vm.$t("trade.product_id"),
      "label-width": "100px",
      required: true
    }
  }, [_vm.latestSamples.length > 0 ? _c("el-select", {
    staticStyle: {
      "margin-bottom": "10px"
    },
    attrs: {
      value: "",
      placeholder: _vm.$t("trade.latest_samples"),
      disabled: _vm.editNote
    },
    on: {
      change: _vm.handleLatestSampleSelect
    }
  }, _vm._l(_vm.latestSamples, function (item) {
    return _c("el-option", {
      key: item.key,
      attrs: {
        label: _vm.latestItemLabel(item),
        value: item
      }
    }, [_c("el-tooltip", {
      attrs: {
        effect: "dark",
        placement: "top-end"
      }
    }, [_c("div", {
      staticStyle: {
        width: "320px"
      },
      attrs: {
        slot: "content"
      },
      slot: "content"
    }, [_vm._v(" " + _vm._s(_vm.latestItemLabel(item)) + " ")]), _c("span", {
      staticClass: "item-label"
    }, [_vm._v(_vm._s(_vm.latestItemLabel(item)))])])], 1);
  }), 1) : _vm._e(), _vm._l(_vm.productItems, function (item, index) {
    return _c("div", {
      key: item.id,
      staticClass: "trade-dialog-add"
    }, [_c("el-cascader", {
      attrs: {
        clearable: "",
        placeholder: _vm.$t("product.name"),
        options: _vm.searchOptions,
        filterable: "",
        disabled: _vm.editNote
      },
      on: {
        change: _vm.handleCascaderChange
      },
      model: {
        value: item.item_code,
        callback: function ($$v) {
          _vm.$set(item, "item_code", $$v);
        },
        expression: "item.item_code"
      }
    }), _c("el-input-number", {
      staticClass: "num",
      attrs: {
        min: 1,
        disabled: _vm.editNote
      },
      model: {
        value: item.quantity,
        callback: function ($$v) {
          _vm.$set(item, "quantity", $$v);
        },
        expression: "item.quantity"
      }
    }), index == _vm.productItems.length - 1 ? _c("el-button", {
      attrs: {
        type: "success",
        size: "medium",
        plain: "",
        disabled: _vm.editNote
      },
      on: {
        click: _vm.addItem
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("common.add")) + " ")]) : _vm._e(), index != 0 ? _c("el-button", {
      staticClass: "delete-btn-in-dialog",
      attrs: {
        type: "danger",
        size: "medium",
        disabled: _vm.editNote,
        plain: ""
      },
      on: {
        click: function ($event) {
          return _vm.deleteItem(index);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("common.delete")) + " ")]) : _vm._e()], 1);
  })], 2)]], 2), _c("upload", {
    ref: "upload",
    attrs: {
      visible: _vm.uploadVisible,
      "dialog-title": _vm.$t("common.import"),
      "upload-msg": _vm.$t("trade.upload_msg1"),
      "accept-file-ext": null,
      url: _vm.uploadUrl,
      accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,\n    application/vnd.ms-excel",
      "upload-response": _vm.uploadResponse
    },
    on: {
      close: function ($event) {
        _vm.uploadVisible = false;
      },
      confirm: _vm.handleUploaded
    },
    scopedSlots: _vm._u([{
      key: "tip",
      fn: function () {
        return [_c("el-link", {
          staticStyle: {
            "margin-top": "10px"
          },
          attrs: {
            type: "primary",
            href: _vm.uploadTemplateUrl
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("trade.template.import")) + " ")])];
      },
      proxy: true
    }])
  }), _c("upload", {
    ref: "ship",
    attrs: {
      "dialog-title": _vm.$t("trade.ship.name"),
      visible: _vm.shipVisible,
      "upload-msg": _vm.$t("trade.upload_msg1"),
      "accept-file-ext": null,
      url: _vm.shipUrl,
      accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,\n    application/vnd.ms-excel",
      "upload-response": _vm.uploadResponse
    },
    on: {
      close: function ($event) {
        _vm.shipVisible = false;
      },
      confirm: _vm.handleUploaded
    },
    scopedSlots: _vm._u([{
      key: "tip",
      fn: function () {
        return [_c("el-link", {
          staticStyle: {
            "margin-top": "10px"
          },
          attrs: {
            type: "primary",
            href: _vm.shipTemplateUrl
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("trade.template.ship")) + " ")])];
      },
      proxy: true
    }])
  }), _c("upload", {
    ref: "completedOrderUpload",
    attrs: {
      visible: _vm.completedOrderUploadVisible,
      "dialog-title": _vm.$t("trade.completedOrderUpload"),
      "upload-msg": _vm.$t("trade.upload_msg1"),
      "accept-file-ext": null,
      url: _vm.completedOrderUploadUrl,
      accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,\n    application/vnd.ms-excel",
      "upload-response": _vm.uploadResponse
    },
    on: {
      close: function ($event) {
        _vm.completedOrderUploadVisible = false;
      },
      confirm: _vm.handleUploaded
    },
    scopedSlots: _vm._u([{
      key: "tip",
      fn: function () {
        return [_c("el-link", {
          staticStyle: {
            "margin-top": "10px"
          },
          attrs: {
            type: "primary",
            href: _vm.completedOrderUploadTemplateUrl
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("trade.template.completedOrderUpload")) + " ")])];
      },
      proxy: true
    }])
  }), _c("upload", {
    ref: "shippable",
    attrs: {
      "dialog-title": _vm.$t("trade.shippable.name"),
      visible: _vm.shippableVisible,
      "upload-msg": _vm.$t("trade.upload_msg1"),
      "accept-file-ext": null,
      url: _vm.shippableUrl,
      accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,\n    application/vnd.ms-excel",
      "upload-response": _vm.uploadResponse,
      data: {
        deliverable: _vm.deliverable
      }
    },
    on: {
      close: function ($event) {
        _vm.shippableVisible = false;
        _vm.deliverable = 0;
      },
      confirm: _vm.handleUploaded
    },
    scopedSlots: _vm._u([{
      key: "upper",
      fn: function () {
        return [_c("el-form", [_c("el-form-item", {
          staticStyle: {
            width: "500px",
            "margin-left": "100px"
          },
          attrs: {
            label: _vm.$t("trade.shippable.status.name")
          }
        }, [_c("el-select", {
          attrs: {
            size: "small"
          },
          model: {
            value: _vm.deliverable,
            callback: function ($$v) {
              _vm.deliverable = $$v;
            },
            expression: "deliverable"
          }
        }, _vm._l(_vm.deliverableOptions, function (item) {
          return _c("el-option", {
            key: item.value,
            attrs: {
              label: item.label,
              value: item.value
            }
          });
        }), 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "tip",
      fn: function () {
        return [_c("el-link", {
          staticStyle: {
            "margin-top": "10px"
          },
          attrs: {
            type: "primary",
            href: _vm.shippableTemplateUrl
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("trade.template.shippable")) + " ")])];
      },
      proxy: true
    }])
  }), _c("edit-dialog", {
    attrs: {
      title: _vm.$t("trade.ship.name"),
      visible: _vm.shipDialogVisible,
      "can-confirm": _vm.shipDialogCanConfirm,
      width: 800
    },
    on: {
      close: () => _vm.shipDialogVisible = false,
      confirm: _vm.shipDialogConfirm
    }
  }, [_vm._l(_vm.expressNo, function (item) {
    return _c("div", {
      key: item
    }, [_c("el-form-item", {
      attrs: {
        "label-width": "100px",
        label: _vm.$t("trade.express_name") + item,
        required: item == 1
      }
    }, [_c("el-input", {
      attrs: {
        placeholder: _vm.$t("common.input_placeholder") + _vm.$t("trade.express_name"),
        clearable: ""
      },
      model: {
        value: _vm.shipForm[`express_name_${item}`],
        callback: function ($$v) {
          _vm.$set(_vm.shipForm, `express_name_${item}`, $$v);
        },
        expression: "shipForm[`express_name_${item}`]"
      }
    })], 1), _c("el-form-item", {
      attrs: {
        "label-width": "100px",
        label: _vm.$t("trade.express_code") + item,
        required: item == 1
      }
    }, [_c("el-input", {
      attrs: {
        placeholder: _vm.$t("common.input_placeholder") + _vm.$t("trade.express_code"),
        clearable: ""
      },
      model: {
        value: _vm.shipForm[`express_code_${item}`],
        callback: function ($$v) {
          _vm.$set(_vm.shipForm, `express_code_${item}`, $$v);
        },
        expression: "shipForm[`express_code_${item}`]"
      }
    })], 1)], 1);
  })], 2), _vm.imported != null ? _c("el-dialog", {
    attrs: {
      title: _vm.$t("trade.import.result"),
      visible: _vm.dialogVisible,
      width: "30%"
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("span", {
    staticClass: "import_result"
  }, [_vm._v(" " + _vm._s(_vm.$t("trade.import.create")) + ":" + _vm._s(_vm.imported.create_trade_count) + " ")]), _c("span", {
    staticClass: "import_result"
  }, [_vm._v(" " + _vm._s(_vm.$t("trade.import.update")) + ":" + _vm._s(_vm.imported.update_trade_count) + " ")]), _c("span", {
    staticClass: "import_result"
  }, [_vm._v(" " + _vm._s(_vm.$t("trade.import.skip")) + ":" + _vm._s(_vm.imported.skip_trade_count) + " ")]), _c("span", {
    staticClass: "import_result"
  }, [_vm._v(" " + _vm._s(_vm.$t("trade.import.failed")) + ":" + _vm._s(_vm.imported.failed_trade_count) + " ")]), _vm.imported.failed_platform_trade_code_list != null && _vm.imported.failed_platform_trade_code_list.length > 0 ? _c("div", {
    staticStyle: {
      margin: "20px 0px 10px 0px",
      display: "flex",
      "column-gap": "10px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("trade.import.failed_list")) + " "), _c("el-link", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.downloadFailedImport
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("trade.import.download")) + " ")])], 1) : _vm._e(), _vm.imported.failed_platform_trade_code_list != null && _vm.imported.failed_platform_trade_code_list.length > 0 ? _c("div", {
    staticStyle: {
      "max-height": "200px",
      "overflow-y": "scroll"
    }
  }, _vm._l(_vm.imported.failed_platform_trade_code_list, function (item) {
    return _c("div", {
      key: item,
      staticClass: "import_result"
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0) : _vm._e(), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        _vm.dialogVisible = false;
        _vm.imported = null;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("common.confirm")))])], 1)]) : _vm._e(), _c("el-dialog", {
    attrs: {
      title: _vm.$t("common.view"),
      visible: _vm.detailDialogVisible,
      width: "720px",
      "custom-class": "info"
    },
    on: {
      "update:visible": function ($event) {
        _vm.detailDialogVisible = $event;
      }
    }
  }, [_vm.rowDetail != null ? _c("div", {
    staticStyle: {
      display: "flex",
      "flex-direction": "column",
      "row-gap": "8px"
    }
  }, [_c("el-descriptions", {
    attrs: {
      title: _vm.$t("trade.push_info"),
      column: 2,
      "label-style": {
        minWidth: "60px"
      }
    }
  }, [_c("el-descriptions-item", {
    attrs: {
      label: _vm.$t("trade.push_status_name")
    }
  }, [_vm._v(" " + _vm._s(_vm.rowDetail.push_status_name) + " ")]), _c("el-descriptions-item", {
    attrs: {
      label: _vm.$t("trade.erp_trade_created")
    }
  }, [_vm._v(" " + _vm._s(_vm.rowDetail.erp_trade_created) + " ")]), _c("el-descriptions-item", {
    attrs: {
      label: _vm.$t("trade.channel")
    }
  }, [_vm._v(" " + _vm._s(_vm.rowDetail.channel.name || _vm.rowDetail.vip_code) + " ")]), _c("el-descriptions-item", {
    attrs: {
      label: _vm.$t("trade.platform_trade_code")
    }
  }, [_vm._v(" " + _vm._s(_vm.rowDetail.platform_trade_code) + " ")])], 1), _c("el-descriptions", {
    attrs: {
      title: _vm.$t("trade.receiver_info"),
      column: 2,
      "label-style": {
        minWidth: "60px"
      }
    }
  }, [_c("el-descriptions-item", {
    attrs: {
      label: _vm.$t("trade.receiver_name")
    }
  }, [_vm._v(" " + _vm._s(_vm.rowDetail.receiver_name) + " ")]), _c("el-descriptions-item", {
    attrs: {
      label: _vm.$t("trade.receiver_mobile")
    }
  }, [_vm._v(" " + _vm._s(_vm.rowDetail.receiver_mobile) + " ")]), _c("el-descriptions-item", {
    attrs: {
      label: _vm.$t("trade.display_addr"),
      span: 2
    }
  }, [_vm._v(" " + _vm._s(_vm.rowDetail.display_addr) + _vm._s(_vm.rowDetail.receiver_address) + " ")])], 1), _c("el-descriptions", {
    attrs: {
      title: _vm.$t("trade.express_info"),
      column: 2,
      "label-style": {
        minWidth: "60px"
      }
    }
  }, [_c("el-descriptions-item", {
    attrs: {
      label: _vm.$t("trade.express_name")
    }
  }, [_vm._v(" " + _vm._s(_vm.rowDetail.express_name) + " ")]), _c("el-descriptions-item", {
    attrs: {
      label: _vm.$t("trade.express_code")
    }
  }, [_vm._v(" " + _vm._s(_vm.rowDetail.express_code) + " ")]), _c("el-descriptions-item", {
    attrs: {
      label: _vm.$t("trade.warehouse_code")
    }
  }, [_vm._v(" " + _vm._s(_vm.rowDetail.warehouse_code) + " ")]), _c("el-descriptions-item", {
    attrs: {
      label: _vm.$t("trade.delivery_at")
    }
  }, [_vm._v(" " + _vm._s(_vm.rowDetail.delivery_at) + " ")]), _c("el-descriptions-item", {
    attrs: {
      label: _vm.$t("trade.seller_memo"),
      span: 2
    }
  }, [_vm._v(" " + _vm._s(_vm.rowDetail.seller_memo) + " ")])], 1), _c("el-descriptions", {
    attrs: {
      title: _vm.$t("trade.other_info"),
      column: 2,
      "label-style": {
        minWidth: "60px"
      }
    }
  }, [_c("el-descriptions-item", {
    attrs: {
      label: _vm.$t("trade.owner")
    }
  }, [_vm._v(" " + _vm._s(_vm.rowDetail.owner) + " ")]), _c("el-descriptions-item", {
    attrs: {
      label: _vm.$t("common.create_at")
    }
  }, [_vm._v(" " + _vm._s(_vm.rowDetail.create_at) + " ")])], 1), _c("el-descriptions", {
    attrs: {
      title: _vm.$t("trade.match_info"),
      column: 5
    }
  }, _vm._l(_vm.flattenItems(_vm.rowDetail.items), function (item, index) {
    return _c("el-descriptions-item", {
      key: index,
      attrs: {
        label: _vm.$t(item.label),
        span: item.span || 1
      }
    }, [_c("div", {
      style: item.style
    }, [_vm._v(" " + _vm._s(item.value) + " ")])]);
  }), 1)], 1) : _vm._e()]), _c("el-dialog", {
    attrs: {
      title: _vm.$t("common.table_cols"),
      visible: _vm.tableColDialogVisible,
      width: "720px"
    },
    on: {
      "update:visible": function ($event) {
        _vm.tableColDialogVisible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "table-cols"
  }, _vm._l(_vm.requestParams.filter(e => e.showInTable != false && e.canEditColumn !== false), function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "col"
    }, [_c("el-switch", {
      model: {
        value: item.showColumn,
        callback: function ($$v) {
          _vm.$set(item, "showColumn", $$v);
        },
        expression: "item.showColumn"
      }
    }), _vm._v(" " + _vm._s(item.label && _vm.$t(item.label) || _vm.$t(`trade.${item.key}`)) + " ")], 1);
  }), 0), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function ($event) {
        _vm.tableColDialogVisible = false;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("common.cancel")))]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        _vm.tableColDialogVisible = false;
        _vm.updateTableCols();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("common.confirm")))])], 1)])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;