"use strict";

var _interopRequireDefault = require("/root/workspace/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
const state = {
  sidebar: {
    opened: _jsCookie.default.get('sidebarStatus') ? !!+_jsCookie.default.get('sidebarStatus') : true,
    withoutAnimation: false
  },
  device: 'desktop',
  unreadCount: 0
};
const mutations = {
  TOGGLE_SIDEBAR: State => {
    const StateClone = State;
    StateClone.sidebar.opened = !StateClone.sidebar.opened;
    StateClone.sidebar.withoutAnimation = false;
    if (StateClone.sidebar.opened) {
      _jsCookie.default.set('sidebarStatus', 1);
    } else {
      _jsCookie.default.set('sidebarStatus', 0);
    }
  },
  CLOSE_SIDEBAR: (State, withoutAnimation) => {
    const StateClone = State;
    _jsCookie.default.set('sidebarStatus', 0);
    StateClone.sidebar.opened = false;
    StateClone.sidebar.withoutAnimation = withoutAnimation;
  },
  OPEN_SIDEBAR: (State, withoutAnimation) => {
    const StateClone = State;
    _jsCookie.default.set('sidebarStatus', 1);
    StateClone.sidebar.opened = true;
    StateClone.sidebar.withoutAnimation = withoutAnimation;
  },
  TOGGLE_DEVICE: (State, device) => {
    const StateClone = State;
    StateClone.device = device;
  },
  SET_UNREAD_COUNT: (State, unreadCount) => {
    const StateClone = State;
    StateClone.unreadCount = unreadCount;
  }
};
const actions = {
  toggleSideBar({
    commit
  }) {
    commit('TOGGLE_SIDEBAR');
  },
  closeSideBar({
    commit
  }, {
    withoutAnimation
  }) {
    commit('CLOSE_SIDEBAR', withoutAnimation);
  },
  openSideBar({
    commit
  }, {
    withoutAnimation
  }) {
    commit('OPEN_SIDEBAR', withoutAnimation);
  },
  toggleDevice({
    commit
  }, device) {
    commit('TOGGLE_DEVICE', device);
  },
  setUnreadCount({
    commit
  }, unreadCount) {
    commit('SET_UNREAD_COUNT', unreadCount);
  }
};
var _default = exports.default = {
  namespaced: true,
  state,
  mutations,
  actions
};