"use strict";

var _interopRequireDefault = require("/root/workspace/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _vuex = require("vuex");
var _index = _interopRequireDefault(require("@/components/Breadcrumb/index.vue"));
var _index2 = _interopRequireDefault(require("@/components/Hamburger/index.vue"));
var _index3 = require("@/i18n/index");
var _timezone = require("@/utils/timezone");
var _EditDialog = _interopRequireDefault(require("@/views/templates/EditDialog.vue"));
var _common = require("@/api/common");
// import Cookies from 'js-cookie';
var _default = exports.default = {
  components: {
    Breadcrumb: _index.default,
    EditDialog: _EditDialog.default,
    Hamburger: _index2.default
  },
  data() {
    return {
      timezones: _timezone.TIMEZONES,
      changePwdVisible: false,
      changePwdForm: {}
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['sidebar', 'name', 'en_name', 'timezone', 'unreadCount'])
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    },
    async logout() {
      await this.$store.dispatch('user/logout');
      this.$router.push(`/login?redirect=${this.$route.fullPath}`);
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      (0, _index3.setup)(lang);
    },
    changeTimezone(idx) {
      this.$store.dispatch('settings/changeSetting', {
        key: 'timezone',
        value: idx
      });
      window.location.reload();
    },
    checkMessage() {
      if (!this.$route.path.startsWith('/message')) {
        this.$router.push('message');
      }
    },
    changePassword() {
      this.changePwdForm = {};
      this.changePwdVisible = true;
    },
    async doChangePwd(form) {
      const res = await (0, _common.update)('/auth/reset_password', form);
      if (res.code === 0) {
        this.$message({
          message: this.$t('common.operation_success'),
          type: 'success'
        });
      }
    }
  }
};