"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'Pagination',
  props: ['total', 'currentPage', 'pageSize'],
  data() {
    return {};
  },
  methods: {
    handleCurrentChange(val) {
      this.$emit('go', val);
    },
    handleSizeChange(val) {
      this.$emit('changeSize', val);
    }
  }
};