"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.filterAsyncRoutes = filterAsyncRoutes;
require("core-js/modules/es.array.push.js");
require("core-js/modules/esnext.iterator.constructor.js");
require("core-js/modules/esnext.iterator.for-each.js");
require("core-js/modules/esnext.iterator.some.js");
var _router = require("@/router");
/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(permissions, route) {
  let result = true;
  if (route.meta && route.meta.permissions) {
    result = permissions.some(permission => route.meta.permissions.includes(permission.code));
  }
  return result;
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param permissions
 */
function filterAsyncRoutes(routes, permissions) {
  const res = [];
  routes.forEach(route => {
    const tmp = {
      ...route
    };
    if (hasPermission(permissions, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, permissions);
      }
      res.push(tmp);
    }
  });
  return res;
}
const state = {
  routes: [],
  addRoutes: []
};
const mutations = {
  SET_ROUTES: (State, routes) => {
    State.addRoutes = routes;
    State.routes = _router.constantRoutes.concat(routes);
  }
};
const actions = {
  generateRoutes({
    commit
  }, permissions) {
    return new Promise(resolve => {
      const accessedRoutes = filterAsyncRoutes(_router.asyncRoutes, permissions);
      commit('SET_ROUTES', accessedRoutes);
      resolve(accessedRoutes);
    });
  }
};
var _default = exports.default = {
  namespaced: true,
  state,
  mutations,
  actions
};