"use strict";

var _interopRequireDefault = require("/root/workspace/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createItem = createItem;
exports.deleteItem = deleteItem;
exports.get = get;
exports.getItem = getItem;
exports.getList = getList;
exports.getListAll = getListAll;
exports.publishItem = publishItem;
exports.search = search;
exports.update = update;
exports.updateItem = updateItem;
var _http = _interopRequireDefault(require("@/utils/http"));
function getList(endpoint, params) {
  return (0, _http.default)({
    url: endpoint,
    method: 'get',
    params
  });
}
function getListAll(endpoint, params) {
  return (0, _http.default)({
    url: `${endpoint}all`,
    method: 'get',
    params
  });
}
function search(endpoint, params) {
  return (0, _http.default)({
    url: `${endpoint}search`,
    method: 'get',
    params
  });
}
function createItem(endpoint, data) {
  return (0, _http.default)({
    url: endpoint,
    method: 'post',
    data
  });
}
function getItem(endpoint, id) {
  return (0, _http.default)({
    url: `${endpoint}${id}`,
    method: 'get'
  });
}
function update(url, data) {
  return (0, _http.default)({
    url,
    method: 'put',
    data
  });
}
function get(url, params) {
  return (0, _http.default)({
    url,
    method: 'get',
    params
  });
}
function updateItem(endpoint, id, data) {
  return (0, _http.default)({
    url: `${endpoint}${id || ''}`,
    method: 'put',
    data
  });
}
function deleteItem(endpoint, id) {
  return (0, _http.default)({
    url: `${endpoint}${id}`,
    method: 'delete'
  });
}
function publishItem(endpoint, id, data) {
  return (0, _http.default)({
    url: `${endpoint}${id}/publish`,
    method: 'put',
    data
  });
}