"use strict";

var _interopRequireDefault = require("/root/workspace/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _vuex = _interopRequireDefault(require("vuex"));
var _getters = _interopRequireDefault(require("./getters"));
var _app = _interopRequireDefault(require("./modules/app"));
var _settings = _interopRequireDefault(require("./modules/settings"));
var _user = _interopRequireDefault(require("./modules/user"));
var _permission = _interopRequireDefault(require("./modules/permission"));
// eslint-disable-next-line import/no-cycle

_vue.default.use(_vuex.default);
const store = new _vuex.default.Store({
  modules: {
    app: _app.default,
    settings: _settings.default,
    user: _user.default,
    permission: _permission.default
  },
  getters: _getters.default
});
var _default = exports.default = store;