"use strict";

var _interopRequireDefault = require("/root/workspace/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _auth = require("@/utils/auth");
var _vue = _interopRequireDefault(require("vue"));
var _default = exports.default = {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    dialogTitle: {
      type: String,
      default: ''
    },
    postFunc: {
      type: Function,
      default: null
    },
    accept: {
      type: String,
      default: 'image/png, image/jpeg'
    },
    acceptFileExt: {
      type: String,
      default: 'jpg/png'
    },
    url: {
      type: String,
      default: `${_vue.default.prototype.$appBaseApi || process.env.VUE_APP_BASE_API}/file/`
    },
    uploadMsg: {
      type: String,
      default: 'common.upload_msg1'
    },
    beforeUpload: {
      type: Function,
      default: null
    },
    uploadResponse: {
      type: Function,
      default: null
    },
    data: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      authHeader: {
        Authorization: `bdoms ${(0, _auth.getToken)()}`
      },
      uploaded: null
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      }
    }
  },
  methods: {
    handleClose() {
      this.uploaded = null;
      this.$refs.upload.clearFiles();
      this.$emit('close');
      this.dialogVisible = false;
    },
    handleConfirm() {
      this.loading = true;
      if (this.postFunc !== null) {
        this.postFunc(this.uploaded).then(() => {
          this.$emit('confirm');
          this.handleClose();
        }).finally(() => {
          this.loading = false;
        });
      } else {
        this.loading = false;
        this.$emit('confirm');
        this.handleClose();
      }
    },
    uploadSuccess(res) {
      if (res.code === 0) {
        this.uploaded = res.data;
      }
      if (this.uploadResponse != null) {
        this.uploadResponse(res);
      }
    }
  }
};