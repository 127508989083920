"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("basic", {
    ref: "table",
    attrs: {
      "page-endpoint": _vm.endpoint,
      "page-name": "price",
      "request-params": _vm.requestParams,
      "format-request-data": _vm.formatRequestData,
      "list-update-time": false,
      "dialog-width": 420,
      filters: [{
        query: "channel_name",
        placeholder: "channel.name"
      }, {
        query: "product_name",
        placeholder: "product.name"
      }],
      "search-bar-right-addition": [{
        title: "common.import",
        onClick: () => {
          _vm.uploadVisible = true;
        }
      }, {
        title: "common.export",
        onClick: _vm.handleExport
      }],
      "operation-width": 150
    }
  }), _c("upload", {
    ref: "price",
    attrs: {
      "dialog-title": _vm.$t("routes.price"),
      visible: _vm.uploadVisible,
      "upload-msg": _vm.$t("trade.upload_msg1"),
      "accept-file-ext": null,
      url: _vm.uploadUrl,
      accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,\n    application/vnd.ms-excel",
      "upload-response": _vm.uploadResponse
    },
    on: {
      close: function ($event) {
        _vm.uploadVisible = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "tip",
      fn: function () {
        return [_c("el-link", {
          staticStyle: {
            "margin-top": "10px"
          },
          attrs: {
            type: "primary",
            href: _vm.uploadTemplateUrl
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("trade.template.price")) + " ")])];
      },
      proxy: true
    }])
  }), _vm.imported != null ? _c("el-dialog", {
    attrs: {
      title: _vm.$t("trade.import.result"),
      visible: _vm.dialogVisible,
      width: "30%"
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("span", {
    staticClass: "import_result"
  }, [_vm._v(" " + _vm._s(_vm.$t("trade.import.create")) + ":" + _vm._s(_vm.imported.create_count) + " ")]), _c("span", {
    staticClass: "import_result"
  }, [_vm._v(" " + _vm._s(_vm.$t("trade.import.update")) + ":" + _vm._s(_vm.imported.update_count) + " ")]), _c("span", {
    staticClass: "import_result"
  }, [_vm._v(" " + _vm._s(_vm.$t("trade.import.skip")) + ":" + _vm._s(_vm.imported.ignore_count) + " ")]), _c("span", {
    staticClass: "import_result"
  }, [_vm._v(" " + _vm._s(_vm.$t("trade.import.failed")) + ":" + _vm._s(_vm.imported.failed_count) + " ")]), _vm.imported.failed_list != null && _vm.imported.failed_list.length > 0 ? _c("div", {
    staticClass: "import_failed",
    staticStyle: {
      "max-height": "200px",
      "overflow-y": "scroll"
    }
  }, [_c("div", [_vm._v(_vm._s(_vm.$t("price.import.failed")))]), _vm._l(_vm.imported.failed_list, function (item) {
    return _c("div", {
      key: item.channel_name
    }, [_vm._v(" " + _vm._s(item.channel_name) + " / " + _vm._s(item.product_name) + " / " + _vm._s(item.price) + " / " + _vm._s(item.reason) + " ")]);
  })], 2) : _vm._e(), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        _vm.dialogVisible = false;
        _vm.imported = null;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("common.confirm")))])], 1)]) : _vm._e()], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;