"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("basic", {
    ref: "table",
    attrs: {
      "page-endpoint": _vm.endpoint,
      "page-name": "channel",
      "request-params": _vm.requestParams,
      "search-bar-right-addition": [{
        title: "common.export",
        onClick: _vm.handleExport
      }],
      "operation-width": 150
    }
  });
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;