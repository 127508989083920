"use strict";

var _interopRequireDefault = require("/root/workspace/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
require("core-js/modules/esnext.iterator.map.js");
var _vue = _interopRequireDefault(require("vue"));
var _index = _interopRequireDefault(require("@/components/SvgIcon/index.vue"));
// svg component

// register globally
_vue.default.component('svg-icon', _index.default);
const req = require.context('./svg', false, /\.svg$/);
const requireAll = requireContext => requireContext.keys().map(requireContext);
requireAll(req);