"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("basic", {
    ref: "table",
    attrs: {
      "page-endpoint": _vm.endpoint,
      "page-name": "stat",
      "request-params": _vm.requestParams,
      "list-create-time": false,
      "list-update-time": false,
      "edit-and-delete-operations": false,
      "show-create-right-button": false,
      "search-bar-right-addition": [{
        title: "common.export",
        onClick: _vm.handleExport
      }],
      filters: [{
        query: "channel_name",
        placeholder: "channel.name"
      }, {
        query: "product_name",
        placeholder: "product.name"
      }, {
        dateRange: ["begin_date", "end_date"],
        clearable: false
      }],
      "query-initial-values": {
        begin_date: _vm.initBegin,
        end_date: _vm.initEnd,
        dateRange: _vm.range
      }
    }
  });
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;