"use strict";

var _interopRequireDefault = require("/root/workspace/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _variables = _interopRequireDefault(require("@/styles/variables.scss"));
var _Logo = _interopRequireDefault(require("./Logo.vue"));
var _SidebarItem = _interopRequireDefault(require("./SidebarItem.vue"));
var _default = exports.default = {
  components: {
    SidebarItem: _SidebarItem.default,
    Logo: _Logo.default
  },
  computed: {
    ...(0, _vuex.mapGetters)(['permission_routes', 'sidebar']),
    routes() {
      return this.$router.options.routes;
    },
    activeMenu() {
      const route = this.$route;
      const {
        meta,
        path
      } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo() {
      return true;
    },
    variables() {
      return _variables.default;
    },
    isCollapse() {
      return !this.sidebar.opened;
    }
  }
};