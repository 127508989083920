"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _index = require("@/i18n/index");
var _default = exports.default = {
  data() {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [{
          required: true,
          trigger: 'blur'
        }]
      },
      loading: false,
      redirect: undefined,
      passwordType: 'password'
    };
  },
  computed: {
    submitEnabled() {
      return this.loginForm.username.length > 0 && this.loginForm.password.length >= 4;
    }
  },
  watch: {
    $route: {
      handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  methods: {
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true;
          const loginData = {
            ...this.loginForm
          };
          this.$store.dispatch('user/login', loginData).then(() => {
            this.$router.push({
              path: '/'
            }).catch(() => {});
          }).finally(() => {
            this.loading = false;
          });
        }
      });
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      (0, _index.setup)(lang);
    }
  }
};