"use strict";

var _interopRequireDefault = require("/root/workspace/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _mixins = _interopRequireDefault(require("@/utils/mixins"));
var _common = require("@/api/common");
var _index = require("@/utils/index");
var _index2 = _interopRequireDefault(require("@/components/Upload/index.vue"));
var _index3 = _interopRequireDefault(require("../basic/index.vue"));
var _default = exports.default = {
  components: {
    Basic: _index3.default,
    Upload: _index2.default
  },
  mixins: [_mixins.default.exportCsv],
  data() {
    return {
      endpoint: '/channel_product_price/',
      requestParams: [{
        key: 'channel.name',
        label: 'channel.name',
        canEdit: false
      },
      // , {
      //   key: 'channel.gjp_code',
      //   label: 'channel.gjp_code',
      //   canEdit: false,
      // }, {
      //   key: 'channel.erp_vip_code',
      //   label: 'channel.erp_vip_code',
      //   canEdit: false,
      // }, {
      //   key: 'channel.erp_shop_code',
      //   label: 'channel.erp_shop_code',
      //   canEdit: false,
      // }
      {
        key: 'product.name',
        label: 'product.name',
        canEdit: false
      },
      // , {
      //   key: 'product.gjp_code',
      //   label: 'product.gjp_code',
      //   canEdit: false,
      // }, {
      //   key: 'product.erp_code',
      //   label: 'product.erp_code',
      //   canEdit: false,
      // }
      {
        key: 'channel_id',
        label: 'product.related_channel_count',
        remoteSearch: '/channel/',
        searchKey: row => row.channel.name,
        initValue: row => row.channel.id,
        showInTable: false
      }, {
        key: 'product_id',
        label: 'channel.related_product_count',
        remoteSearch: '/product/',
        searchKey: row => row.product.name,
        initValue: row => row.product.id,
        showInTable: false
      }, {
        key: 'price',
        inputType: 'number'
      }],
      relateChannel: null,
      relateChannelOptions: [],
      relateProduct: null,
      relateProductOptions: [],
      loading: false,
      uploadUrl: `${process.env.VUE_APP_BASE_API}/channel_product_price/upload`,
      uploadVisible: false,
      uploadTemplateUrl: `${process.env.VUE_APP_STATIC}/page/static/template/import_channel_product_price_tempalte_20220807.xlsx`,
      dialogVisible: false,
      imported: null
    };
  },
  methods: {
    formatRequestData(data) {
      return {
        ...data,
        price: parseFloat(data.price)
      };
    },
    exportRequest() {
      const requestData = {
        ...this.$refs.table.query
      };
      requestData.page = this.page_exporting;
      requestData.per_page = this.per_page_exporting;
      return (0, _common.getList)(this.endpoint, requestData);
    },
    exportMap(item) {
      return {
        [this.$t('price.price')]: item.price,
        渠道名称: item.channel.name,
        渠道管家婆编码: item.channel.gjp_code,
        渠道ERP会员编码: item.channel.erp_vip_code,
        ERP产品名称: item.product.name,
        产品管家婆编码: item.product.gjp_code,
        产品ERP编码: item.product.erp_code,
        产品管家婆名称: item.product.gjp_name
      };
    },
    outputName() {
      return `${(0, _index.parseTime)(new Date(), '{y}{m}{d}_{h}{i}{s}')}_BDOMS_导出价格配置.xlsx`;
    },
    uploadResponse(res) {
      const {
        code,
        data
      } = res;
      if (code === 0) {
        this.imported = data;
        this.uploadVisible = false;
        if (this.imported != null) {
          this.dialogVisible = true;
        }
      } else {
        this.$message.error(res.error_message);
      }
    }
  }
};