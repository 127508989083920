"use strict";

var _interopRequireDefault = require("/root/workspace/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _mixins = _interopRequireDefault(require("@/utils/mixins"));
var _common = require("@/api/common");
var _index = require("@/utils/index");
var _index2 = _interopRequireDefault(require("../basic/index.vue"));
var _default = exports.default = {
  components: {
    Basic: _index2.default
  },
  mixins: [_mixins.default.exportCsv],
  data() {
    const now = new Date();
    const begin = new Date();
    begin.setDate(begin.getDate() - 7);
    const range = [begin, now];
    return {
      endpoint: '/stat/trade',
      requestParams: [{
        key: 'channel.name'
      }, {
        key: 'channel.gjp_code'
      }, {
        key: 'product.name'
      }, {
        key: 'product.gjp_code'
      }, {
        key: 'quantity',
        align: 'right',
        style: () => 'width: 100%',
        width: 100
      }, {
        key: 'total',
        align: 'right',
        style: () => 'width: 100%',
        width: 100
      }],
      initBegin: (0, _index.parseTime)(begin),
      initEnd: (0, _index.parseTime)(now),
      range
    };
  },
  methods: {
    exportRequest() {
      const requestData = {
        ...this.$refs.table.query
      };
      requestData.page = this.page_exporting;
      requestData.per_page = this.per_page_exporting;
      return (0, _common.getList)(this.endpoint, requestData);
    },
    exportMap(item) {
      return {
        渠道名称: item.channel.name,
        渠道管家婆编码: item.channel.gjp_code,
        渠道ERP会员编码: item.channel.erp_vip_code,
        产品名称: item.product.name,
        产品管家婆编码: item.product.gjp_code,
        产品ERP编码: item.product.erp_code,
        [this.$t('stat.total')]: item.total,
        [this.$t('stat.quantity')]: item.quantity
      };
    },
    outputName() {
      return `${(0, _index.parseTime)(new Date(), '{y}{m}{d}_{h}{i}{s}')}_BDOMS_导出数据统计.xlsx`;
    }
  }
};