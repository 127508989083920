"use strict";

var _interopRequireDefault = require("/root/workspace/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.authInfo = authInfo;
exports.authLogin = authLogin;
var _http = _interopRequireDefault(require("@/utils/http"));
// eslint-disable-next-line import/no-cycle

function authLogin(data = {}) {
  return (0, _http.default)({
    url: '/auth/login',
    method: 'post',
    data
  });
}
function authInfo() {
  return (0, _http.default)({
    url: '/auth/info',
    method: 'get'
  });
}