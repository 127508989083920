"use strict";

var _interopRequireDefault = require("/root/workspace/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/esnext.iterator.constructor.js");
require("core-js/modules/esnext.iterator.for-each.js");
var _mixins = _interopRequireDefault(require("@/utils/mixins"));
var _common = require("@/api/common");
var _index = require("@/utils/index");
var _index2 = _interopRequireDefault(require("../basic/index.vue"));
var _default = exports.default = {
  components: {
    Basic: _index2.default
  },
  mixins: [_mixins.default.exportCsv],
  data() {
    return {
      endpoint: '/channel/',
      requestParams: [{
        key: 'name'
      }, {
        key: 'gjp_code'
      }, {
        key: 'erp_vip_code'
      }, {
        key: 'erp_shop_code'
      }, {
        key: 'related_product_count',
        canEdit: false
      }]
    };
  },
  methods: {
    exportRequest() {
      const requestData = {
        ...this.$refs.table.query
      };
      requestData.page = this.page_exporting;
      requestData.per_page = this.per_page_exporting;
      return (0, _common.getList)(this.endpoint, requestData);
    },
    exportMap(item) {
      const map = {};
      const ignored = ['id', 'create_at', 'update_at'];
      Object.keys(item).forEach(k => {
        if (ignored.indexOf(k) < 0) {
          map[this.$t(`channel.${k}`)] = item[k];
        }
      });
      return map;
    },
    outputName() {
      return `${(0, _index.parseTime)(new Date(), '{y}{m}{d}_{h}{i}{s}')}_BDOMS_导出渠道.xlsx`;
    }
  }
};