"use strict";

var _interopRequireDefault = require("/root/workspace/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
require("core-js/modules/esnext.iterator.constructor.js");
require("core-js/modules/esnext.iterator.filter.js");
var _elementUi = require("element-ui");
var _nprogress = _interopRequireDefault(require("nprogress"));
var _auth = require("@/utils/auth");
var _getPageTitle = _interopRequireDefault(require("@/utils/get-page-title"));
var _router = _interopRequireDefault(require("./router"));
var _store = _interopRequireDefault(require("./store"));
require("nprogress/nprogress.css");
// progress bar
// get token from cookie

// progress bar style

_nprogress.default.configure({
  showSpinner: false
}); // NProgress Configuration

const whiteList = ['/login']; // no redirect whitelist

_router.default.beforeEach(async (to, from, next) => {
  _nprogress.default.start();
  document.title = (0, _getPageTitle.default)(); // getPageTitle(to.meta.title);

  // determine whether the user has logged in
  const userToken = (0, _auth.getToken)();
  if (userToken) {
    if (to.path === '/login') {
      next({
        path: '/'
      });
      _elementUi.Message.warning('已登录');
    } else {
      const hasPermissions = _store.default.getters.permissions && _store.default.getters.permissions.length > 0;
      if (hasPermissions) {
        next();
      } else {
        try {
          // get user info
          const {
            permissions
          } = await _store.default.dispatch('user/getInfo');
          if (permissions && permissions.length > 0) {
            // based on permission
            // generate accessible routes map based on permissions
            const accessRoutes = await _store.default.dispatch('permission/generateRoutes', permissions);
            // dynamically add accessible routes
            _router.default.addRoutes(accessRoutes);
            // hack method to ensure that addRoutes is complete
            // set the replace: true, so the navigation will not leave a history record

            // business role need to route to /trade
            if (to.path === '/' && permissions.filter(e => e.code === 'business').length > 0) {
              next({
                path: '/trade',
                replace: true
              });
            } else {
              next({
                ...to,
                replace: true
              });
            }
          } else {
            _elementUi.Message.warning('此帐号没有权限进入管理后台');
            // remove token and go to login page to re-login
            await _store.default.dispatch('user/resetToken');
            next('/login');
            _nprogress.default.done();
          }
        } catch (error) {
          _elementUi.Message.error(error || 'Has Error');
          // remove token and go to login page to re-login
          await _store.default.dispatch('user/resetToken');
          next('/login');
          _nprogress.default.done();
        }
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next('/login');
    }
    _nprogress.default.done();
  }
});
_router.default.afterEach(() => {
  // finish progress bar
  _nprogress.default.done();
});