"use strict";

var _interopRequireDefault = require("/root/workspace/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
require("core-js/modules/esnext.iterator.constructor.js");
require("core-js/modules/esnext.iterator.filter.js");
require("core-js/modules/esnext.iterator.for-each.js");
require("core-js/modules/esnext.iterator.map.js");
var _mixins = _interopRequireDefault(require("@/utils/mixins"));
var _common = require("@/api/common");
var _index = require("@/utils/index");
var _elementChinaAreaData = require("element-china-area-data");
var _table = _interopRequireDefault(require("../templates/table.vue"));
var _EditDialog = _interopRequireDefault(require("../templates/EditDialog.vue"));
var _default = exports.default = {
  components: {
    TemplateTable: _table.default,
    EditDialog: _EditDialog.default
  },
  mixins: [_mixins.default.list, _mixins.default.format],
  props: {
    pageEndpoint: {
      type: String,
      required: true
    },
    pageName: {
      type: String,
      required: true
    },
    requestParams: {
      type: Array,
      required: true
    },
    formatRequestData: {
      type: Function,
      default: null
    },
    canConfirmFunc: {
      type: Function,
      default: null
    },
    onEditRow: {
      type: Function,
      default: null
    },
    listCreateTime: {
      type: Boolean,
      default: true
    },
    listUpdateTime: {
      type: Boolean,
      default: true
    },
    showOperation: {
      type: Function,
      default: null
    },
    searchBarRightAddition: {
      type: Array,
      default: () => []
    },
    filters: {
      type: Array,
      default: () => [{
        query: 'keyword',
        placeholder: 'common.keyword'
      }]
    },
    dialogWidth: {
      type: Number,
      default: 800
    },
    additionalOperations: {
      type: Array,
      default: () => []
    },
    suffixOperations: {
      type: Array,
      default: () => []
    },
    operationWidth: {
      type: Number,
      default: 140
    },
    formatDataList: {
      type: Function,
      default: null
    },
    spanMethod: {
      type: Function,
      default: null
    },
    stripe: {
      type: Boolean,
      default: true
    },
    supportSelection: {
      type: Boolean,
      default: false
    },
    handleSelectionChange: {
      type: Function,
      default: () => {}
    },
    labelPosition: {
      type: String,
      default: 'top'
    },
    afterHandleOpen: {
      type: Function,
      default: null
    },
    reorderTableCols: {
      type: Function,
      default: null
    },
    dataListFunction: {
      type: Function,
      default: null
    },
    showPagination: {
      type: Boolean,
      default: true
    },
    showSearchButton: {
      type: Boolean,
      default: true
    },
    showSearchRigthButton: {
      type: Boolean,
      default: true
    },
    showCreateRightButton: {
      type: Boolean,
      default: true
    },
    editAndDeleteOperations: {
      type: Boolean,
      default: true
    },
    queryInitialValues: {
      type: Object,
      default: null
    },
    editTitle: {
      type: String,
      default: null
    },
    skipFormValidation: {
      type: Boolean,
      default: false
    },
    updateEndpoint: {
      type: String,
      default: null
    },
    operationFixed: {
      type: String,
      default: null
    },
    operationDropdown: {
      type: Boolean,
      default: false
    },
    operationStyle: {
      type: Function,
      default: null
    },
    sortChange: {
      type: Function,
      default: () => {}
    },
    dialogCustomClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      rules: {},
      fields: {},
      editableParams: [],
      searchOptions: {},
      searchBarRightAll: [],
      operations: [],
      dataList: null,
      options: _elementChinaAreaData.regionData
    };
  },
  created() {
    this.operations = this.additionalOperations;
    if (this.editAndDeleteOperations) {
      this.operations = this.operations.concat([{
        type: 'primary',
        title: 'common.edit',
        onClick: row => this.onEdit(row),
        style: this.operationStyle != null && this.operationStyle('edit'),
        show: r => this.showOperation == null ? true : this.showOperation(r, 'edit')
      }, {
        type: 'danger',
        title: 'common.delete',
        onClick: row => {
          this.handleDelete(row, 'common.delete_msg', 'common.alert');
        },
        style: this.operationStyle != null && this.operationStyle('delete'),
        show: r => this.showOperation == null ? true : this.showOperation(r, 'delete')
      }]);
    }
    if (this.suffixOperations != null && this.suffixOperations.length > 0) {
      this.operations = this.operations.concat(this.suffixOperations);
    }
    this.endpoint = this.pageEndpoint;
    this.editableParams = this.requestParams.filter(e => e.canEdit !== false);
    this.editableParams.forEach(e => {
      if (!e.notRequired) {
        this.rules[e.key] = [{
          required: true,
          message: this.$t('common.input_placeholder') + this.$t(`${this.pageName}.${e.key}`),
          trigger: 'blur'
        }];
      }
      this.fields[e.key] = e.defaultValue || '';
    });
    if (this.showSearchRigthButton) {
      this.searchBarRightAll = [];
      if (this.showCreateRightButton) {
        this.searchBarRightAll.push({
          title: 'common.create',
          icon: 'el-icon-plus',
          onClick: () => this.onEdit(null)
        });
      }
      this.searchBarRightAll = this.searchBarRightAll.concat(this.searchBarRightAddition);
    }
    if (this.queryInitialValues) {
      this.query = {
        ...this.queryInitialValues,
        ...this.query
      };
    }
    this.loadList();
  },
  methods: {
    updateColumns() {
      this.columns = this.requestParams.filter(e => e.showInTable !== false && e.showColumn !== false).map(e => ({
        ...e,
        label: e.label || `${this.pageName}.${e.key}`
      }));
      if (this.listCreateTime) {
        this.columns = this.columns.concat([{
          key: 'create_at',
          label: 'common.create_at'
        }]);
      }
      if (this.listUpdateTime) {
        this.columns = this.columns.concat([{
          key: 'update_at',
          label: 'common.update_at'
        }]);
      }
      if (this.reorderTableCols) {
        this.columns = this.reorderTableCols(this.columns);
      }
    },
    async loadList() {
      this.listloading = true;
      const requestData = {
        ...this.query
      };
      const res = this.dataListFunction != null ? await this.dataListFunction(requestData) : await (0, _common.getList)(this.endpoint, requestData);
      const {
        data
      } = res;
      this.dataList = data.items.map(this.formatTimeInData);
      if (this.formatDataList != null) {
        this.dataList = this.formatDataList(this.dataList);
      }
      this.updateColumns();
      this.meta.total = data.total;
      this.meta.current_page = data.page;
      this.listloading = false;
    },
    canConfirm(form) {
      return this.canConfirmFunc != null ? this.canConfirmFunc(form) : this.editableParams.filter(e => {
        if (e.remoteSearch != null) {
          if (form[e.key] == null || form[e.key] === '') {
            return true;
          }
        }
        if (e.canConfirm != null) {
          return !e.canConfirm(form);
        }
        return !(form[e.key] != null && form[e.key].length !== 0);
      }).length === 0;
    },
    async remoteSearch(query, searchEndpoint, searchParam) {
      this.listloading = true;
      if (searchParam == null) {
        searchParam = {};
      }
      const res = await (0, _common.getListAll)(searchEndpoint, {
        keyword: query,
        ...searchParam
      });
      if (res != null && res.code === 0) {
        this.searchOptions[searchEndpoint] = res.data;
      }
      this.listloading = false;
    },
    async onEdit(row) {
      const editRow = row != null ? (0, _index.deepClone)(row) : null;
      this.editObject = this.onEditRow != null ? this.onEditRow(editRow) : editRow;
      this.editObject = await this.afterOnRowEdit(this.editObject);
      this.editDialogVisible = true;
    },
    async afterOnRowEdit(row) {
      // eslint-disable-next-line no-restricted-syntax
      for (const e of this.editableParams) {
        if (e.remoteSearch != null && e.searchKey != null && e.initValue != null) {
          if (row != null) {
            // eslint-disable-next-line no-await-in-loop
            await this.remoteSearch(await e.searchKey(row), e.remoteSearch, e.remoteSearchParam);
            row[e.key] = e.initValue(row);
          } else {
            this.remoteSearch(null, e.remoteSearch, e.remoteSearchParam);
          }
        }
      }
      return row;
    },
    handleDateQueryChange(item) {
      if (this.query.dateRange != null) {
        [this.query[item[0]], this.query[item[1]]] = this.query.dateRange;
      } else {
        this.query[item[0]] = null;
        this.query[item[1]] = null;
      }
      this.loadList();
    },
    handleClose() {
      this.editDialogVisible = false;
      this.searchOptions = {};
      this.$emit('close');
    }
  }
};