"use strict";

var _interopRequireDefault = require("/root/workspace/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
require("core-js/modules/esnext.iterator.constructor.js");
require("core-js/modules/esnext.iterator.for-each.js");
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _auth = require("@/utils/auth");
var _timezone = require("@/utils/timezone");
var _vue = _interopRequireDefault(require("vue"));
var _index = _interopRequireDefault(require("@/router/index"));
var _index2 = _interopRequireDefault(require("@/store/index"));
// eslint-disable-next-line import/no-cycle

const removeEmptyProps = obj => {
  if (obj) {
    const newObj = Array.isArray(obj) ? [] : {};
    Object.keys(obj).forEach(key => {
      if (obj[key] && typeof obj[key] === 'object') {
        newObj[key] = removeEmptyProps(obj[key]); // recurse
      } else if (obj[key] != null) {
        // include undefined
        newObj[key] = obj[key]; // copy value
      }
    });
    return newObj;
  }
  return obj;
};

// create an axios instance
const service = _axios.default.create({
  baseURL: _vue.default.prototype.$appBaseApi || process.env.VUE_APP_BASE_API,
  timeout: 10000,
  // request timeout
  withCredentials: true,
  transformRequest: [data => removeEmptyProps(data), ..._axios.default.defaults.transformRequest]
});
// request interceptor
service.interceptors.request.use(config => {
  const configClone = config;
  configClone.headers.Authorization = `bdoms ${(0, _auth.getToken)()}`;
  configClone.headers.TZ = _timezone.TIMEZONES[window.vue.$store.getters.timezone].value;
  return configClone;
}, error => {
  Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
 */

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
response => {
  const res = response.data;
  // if the custom code is not 20000, it is judged as an error.
  if (res.code !== 0) {
    (0, _elementUi.Message)({
      message: res.error_message || 'Error',
      type: 'error',
      duration: 3 * 1000
    });
  } else if (res.code === 401) {
    _index2.default.dispatch('user/logout').then(() => {
      _index.default.push({
        path: '/login'
      }).catch(() => {});
    });
  }
  return res;
}, error => {
  if (error.response.status === 401) {
    // Message({
    //   message: 'login expired',
    //   type: 'warning',
    //   duration: 2 * 1000,
    // });

    _index2.default.dispatch('user/logout').then(() => {
      _index.default.push({
        path: '/login'
      }).catch(() => {});
    });
    return Promise.reject(error);
  }
  // console.log(`err${res.code}`); // for debug
  (0, _elementUi.Message)({
    message: error.message,
    type: 'error',
    duration: 3 * 1000
  });
  return Promise.reject(error);
});
var _default = exports.default = service;