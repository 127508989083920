"use strict";

var _interopRequireDefault = require("/root/workspace/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;
require("core-js/modules/es.array.push.js");
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/layout"));
_vue.default.use(_vueRouter.default);
const routes = [{
  path: '/',
  component: _layout.default,
  redirect: '/channel',
  children: [{
    path: '',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/channel/index'))),
    meta: {
      title: 'routes.channel',
      permissions: ['admin', 'service']
    }
  }]
}, {
  path: '/product',
  component: _layout.default,
  redirect: '/product',
  children: [{
    path: '',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/product/index'))),
    meta: {
      title: 'routes.product',
      permissions: ['admin', 'service']
    }
  }]
}, {
  path: '/price',
  component: _layout.default,
  redirect: '/price',
  children: [{
    path: '',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/price/index'))),
    meta: {
      title: 'routes.price',
      permissions: ['admin', 'service']
    }
  }]
}, {
  path: '/trade',
  component: _layout.default,
  redirect: '/trade',
  children: [{
    path: '',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/trade/index'))),
    meta: {
      title: 'routes.trade'
    }
  }]
}, {
  path: '/user',
  component: _layout.default,
  redirect: '/user',
  children: [{
    path: '',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/user/index'))),
    meta: {
      title: 'routes.user',
      permissions: ['admin']
    }
  }]
}, {
  path: '/role',
  component: _layout.default,
  redirect: '/role',
  children: [{
    path: '',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/role/index'))),
    meta: {
      title: 'routes.role',
      permissions: ['admin']
    }
  }]
}, {
  path: '/address',
  component: _layout.default,
  redirect: '/address',
  children: [{
    path: '',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/address/index'))),
    meta: {
      title: 'routes.address',
      permissions: ['admin', 'service']
    }
  }]
}, {
  path: '/stat',
  component: _layout.default,
  redirect: '/stat',
  children: [{
    path: '',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/stat/index'))),
    meta: {
      title: 'routes.stat',
      permissions: ['admin']
    }
  }]
}];

// 404 page must be placed at the end !!!
routes.push({
  path: '*',
  redirect: '/404',
  hidden: true
});
const asyncRoutes = exports.asyncRoutes = routes;

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
const constantRoutes = exports.constantRoutes = [{
  path: '/login',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/login/index'))),
  hidden: true
}, {
  path: '/404',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/404'))),
  hidden: true
}];
const createRouter = () => new _vueRouter.default({
  // mode: 'history', // require service support
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRoutes
});
const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var _default = exports.default = router;