"use strict";

var _interopRequireDefault = require("/root/workspace/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _settings = _interopRequireDefault(require("@/settings"));
const {
  showSettings,
  fixedHeader,
  sidebarLogo
} = _settings.default;
let timezone = 1;
const localTimezone = localStorage.getItem('timezone');
if (localTimezone != null) {
  timezone = localTimezone;
}
const state = {
  showSettings,
  fixedHeader,
  sidebarLogo,
  timezone
};
const mutations = {
  CHANGE_SETTING: (State, {
    key,
    value
  }) => {
    const stateClone = State;
    stateClone[key] = value;
    if (key === 'timezone') {
      localStorage.setItem('timezone', value);
    }
  }
};
const actions = {
  changeSetting({
    commit
  }, data) {
    commit('CHANGE_SETTING', data);
  }
};
var _default = exports.default = {
  namespaced: true,
  state,
  mutations,
  actions
};