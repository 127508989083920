"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/esnext.iterator.map.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content"
  }, [_c("el-tabs", {
    attrs: {
      editable: "",
      type: "card"
    },
    on: {
      edit: _vm.editGroup,
      "tab-click": function ($event) {
        _vm.detectClick($event).then(_vm.tabClicked);
      }
    },
    model: {
      value: _vm.activeName,
      callback: function ($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, _vm._l(_vm.dataList, function (item) {
    return _c("el-tab-pane", {
      key: item.id,
      attrs: {
        label: item.name,
        name: item.name
      }
    });
  }), 1), _vm.dataList.map(e => e.name).indexOf(_vm.activeName) >= null ? _c("basic", {
    ref: "basic",
    attrs: {
      "page-endpoint": _vm.endpoint,
      "page-name": "product",
      "request-params": _vm.requestParams,
      "data-list-function": _vm.dataListFunction,
      "search-bar-right-addition": [{
        title: "common.export",
        onClick: _vm.handleExport
      }],
      filters: [{
        query: "keyword",
        placeholder: "common.keyword"
      }],
      "operation-width": 150
    }
  }) : _vm._e(), _c("edit-dialog", {
    attrs: {
      "form-fields": {
        name: "",
        idx: 1000
      },
      "form-rules": {
        name: [{
          required: true,
          message: _vm.$t("product.brand.name_placeholder"),
          trigger: "blur"
        }]
      },
      endpoint: _vm.brandEndpoint,
      width: 410,
      "can-confirm": form => form.name != null && form.name.length > 0,
      object: _vm.editObject,
      visible: _vm.editDialogVisible,
      title: _vm.editObject === null ? _vm.$t("product.brand.create") : _vm.$t("product.brand.edit")
    },
    on: {
      close: function ($event) {
        _vm.editDialogVisible = false;
      },
      confirm: _vm.loadList
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (slotProp) {
        return [_c("el-form-item", {
          attrs: {
            "label-width": "100px",
            label: _vm.$t("product.brand.name"),
            prop: "name"
          }
        }, [_c("el-input", {
          attrs: {
            placeholder: _vm.$t("product.brand.name_placeholder"),
            clearable: "",
            disabled: slotProp.loading
          },
          model: {
            value: slotProp.form.name,
            callback: function ($$v) {
              _vm.$set(slotProp.form, "name", $$v);
            },
            expression: "slotProp.form.name"
          }
        })], 1), _c("el-form-item", {
          attrs: {
            "label-width": "100px",
            label: _vm.$t("common.index")
          }
        }, [_c("el-input-number", {
          attrs: {
            min: 1,
            disabled: slotProp.loading
          },
          model: {
            value: slotProp.form.idx,
            callback: function ($$v) {
              _vm.$set(slotProp.form, "idx", $$v);
            },
            expression: "slotProp.form.idx"
          }
        })], 1)];
      }
    }])
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;