"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("basic", {
    attrs: {
      "page-endpoint": _vm.endpoint,
      "page-name": "role",
      "request-params": _vm.requestParams,
      "data-list-function": _vm.dataListFunction,
      "show-pagination": false,
      "show-search-button": false,
      "show-search-rigth-button": false,
      filters: [],
      "list-update-time": false,
      "list-create-time": false,
      "edit-and-delete-operations": false,
      "operation-width": 200,
      "additional-operations": [{
        type: "warning",
        title: "user.adjust_channel",
        onClick: _vm.adjustChannel,
        show: r => {
          return r.id === 3;
        }
      }, {
        type: "warning",
        title: "user.adjust_product",
        onClick: _vm.adjustProduct,
        show: r => {
          return r.id === 3;
        }
      }]
    }
  }), _c("edit-dialog", {
    attrs: {
      title: _vm.adjustDialogVisible == 1 ? _vm.$t("user.adjust_channel") : _vm.$t("user.adjust_product"),
      visible: _vm.adjustDialogVisible != 0,
      "can-confirm": () => true,
      width: 630
    },
    on: {
      close: () => _vm.adjustDialogVisible = 0,
      confirm: _vm.adjusted
    }
  }, [[_vm.adjustDialogVisible != 0 ? _c("el-transfer", {
    attrs: {
      filterable: "",
      titles: [_vm.$t("user.all"), _vm.$t("user.adjusted")],
      data: _vm.adjustDialogVisible == 1 ? _vm.allChannels : _vm.allProducts
    },
    model: {
      value: _vm.adjustValue,
      callback: function ($$v) {
        _vm.adjustValue = $$v;
      },
      expression: "adjustValue"
    }
  }) : _vm._e()]], 2)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;