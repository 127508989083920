"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    class: `edit-dialog ${_vm.dialogCustomClass}`,
    attrs: {
      title: _vm.title,
      "close-on-click-modal": false,
      visible: _vm.dialogVisible,
      width: `${_vm.width}px`
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      },
      close: _vm.handleClose,
      open: _vm.handleOpen
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": _vm.labelPosition,
      inline: true
    }
  }, [_vm._t("default", null, {
    form: _vm.form,
    loading: _vm.loading
  })], 2), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      disabled: _vm.loading
    },
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v(_vm._s(_vm.$t("common.cancel")))]), _c("el-button", {
    attrs: {
      type: "primary",
      disabled: _vm.loading || !_vm.canConfirm(_vm.form)
    },
    on: {
      click: _vm.handleConfirm
    }
  }, [_vm._v(_vm._s(_vm.$t("common.confirm")))])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;