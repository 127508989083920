"use strict";

var _interopRequireDefault = require("/root/workspace/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getToken = getToken;
exports.removeToken = removeToken;
exports.setToken = setToken;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
const TokenKey = 'hb_token';
function getToken() {
  return _jsCookie.default.get(`${TokenKey}`);
}
function setToken(token) {
  return _jsCookie.default.set(`${TokenKey}`, token);
}
function removeToken() {
  return _jsCookie.default.remove(`${TokenKey}`);
}