"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "pagination-block"
  }, [_c("el-pagination", {
    attrs: {
      background: "",
      "current-page": _vm.currentPage || 1,
      "page-size": _vm.pageSize || 10,
      "page-sizes": [10, 20, 30, 50, 100],
      layout: "sizes, total, prev, pager, next, jumper",
      total: _vm.total || 0
    },
    on: {
      "current-change": _vm.handleCurrentChange,
      "size-change": _vm.handleSizeChange
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;