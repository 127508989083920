"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("basic", {
    ref: "table",
    attrs: {
      "page-endpoint": _vm.endpoint,
      "page-name": "user",
      "request-params": _vm.requestParams,
      "on-edit-row": _vm.onEditRow,
      "format-request-data": _vm.formatRequestData,
      "can-confirm-func": _vm.canConfirm,
      "operation-width": 350,
      "additional-operations": [{
        type: "warning",
        title: "user.adjust_channel",
        onClick: _vm.adjustChannel,
        show: r => {
          return r.role.id === 3;
        }
      }, {
        type: "warning",
        title: "user.adjust_product",
        onClick: _vm.adjustProduct,
        show: r => {
          return r.role.id === 3;
        }
      }]
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (slotProp) {
        return [_vm.addUser ? _c("el-form-item", {
          attrs: {
            "label-width": "100px",
            label: _vm.$t("user.username"),
            required: true
          }
        }, [_c("el-input", {
          attrs: {
            placeholder: _vm.$t("common.input_placeholder") + _vm.$t("user.username"),
            clearable: "",
            disabled: slotProp.loading
          },
          model: {
            value: _vm.form.username,
            callback: function ($$v) {
              _vm.$set(_vm.form, "username", $$v);
            },
            expression: "form.username"
          }
        })], 1) : _vm._e(), _c("el-form-item", {
          attrs: {
            "label-width": "100px",
            label: _vm.$t("user.name")
          }
        }, [_c("el-input", {
          attrs: {
            placeholder: _vm.$t("common.input_placeholder") + _vm.$t("user.name"),
            clearable: "",
            disabled: slotProp.loading
          },
          model: {
            value: _vm.form.name,
            callback: function ($$v) {
              _vm.$set(_vm.form, "name", $$v);
            },
            expression: "form.name"
          }
        })], 1), _c("el-form-item", {
          attrs: {
            "label-width": "100px",
            label: _vm.$t("user.password"),
            required: _vm.addUser
          }
        }, [_c("el-input", {
          attrs: {
            placeholder: _vm.$t("common.input_placeholder") + _vm.$t("user.password"),
            clearable: "",
            "show-password": true,
            disabled: slotProp.loading
          },
          model: {
            value: _vm.form.password,
            callback: function ($$v) {
              _vm.$set(_vm.form, "password", $$v);
            },
            expression: "form.password"
          }
        })], 1), _c("el-form-item", {
          attrs: {
            "label-width": "100px",
            label: _vm.$t("user.role"),
            required: true
          }
        }, [_c("el-select", {
          attrs: {
            placeholder: _vm.$t("common.select_placeholder") + _vm.$t("user.role")
          },
          model: {
            value: _vm.form.role_id,
            callback: function ($$v) {
              _vm.$set(_vm.form, "role_id", $$v);
            },
            expression: "form.role_id"
          }
        }, _vm._l(_vm.roles, function (item) {
          return _c("el-option", {
            key: item.id,
            attrs: {
              label: item.name,
              value: item.id
            }
          });
        }), 1)], 1), _c("el-form-item", {
          attrs: {
            "label-width": "100px",
            label: _vm.$t("user.status")
          }
        }, [_c("el-switch", {
          attrs: {
            "active-color": "#13ce66",
            "inactive-color": "#ff4949"
          },
          model: {
            value: _vm.form.status,
            callback: function ($$v) {
              _vm.$set(_vm.form, "status", $$v);
            },
            expression: "form.status"
          }
        })], 1)];
      }
    }])
  }), _c("edit-dialog", {
    attrs: {
      title: _vm.adjustDialogVisible == 1 ? _vm.$t("user.adjust_channel") : _vm.$t("user.adjust_product"),
      visible: _vm.adjustDialogVisible != 0,
      "can-confirm": () => true,
      width: 630
    },
    on: {
      close: () => _vm.adjustDialogVisible = 0,
      confirm: _vm.adjusted
    }
  }, [[_vm.adjustDialogVisible != 0 ? _c("el-transfer", {
    attrs: {
      filterable: "",
      titles: [_vm.$t("user.all"), _vm.$t("user.adjusted")],
      data: _vm.adjustDialogVisible == 1 ? _vm.allChannels : _vm.allProducts
    },
    model: {
      value: _vm.adjustValue,
      callback: function ($$v) {
        _vm.adjustValue = $$v;
      },
      expression: "adjustValue"
    }
  }) : _vm._e()]], 2)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;