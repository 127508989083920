"use strict";

var _interopRequireDefault = require("/root/workspace/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _auth = require("@/utils/auth");
var _router = require("@/router");
var _auth2 = require("@/api/auth");
var _jsCookie = _interopRequireDefault(require("js-cookie"));
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */

// eslint-disable-next-line import/no-cycle

// get user info remote
const state = {
  token: (0, _auth.getToken)(),
  name: '',
  en_name: '',
  username: '',
  permissions: []
};
const mutations = {
  SET_TOKEN: (State, token) => {
    state.token = token;
  },
  SET_NAME: (State, name) => {
    state.name = name;
  },
  SET_ENNAME: (State, en_name) => {
    state.en_name = en_name;
  },
  SET_USERNAME: (State, username) => {
    state.username = username;
  },
  SET_PERMISSIONS: (State, permissions) => {
    state.permissions = permissions;
  },
  RESET_STATE: State => {
    state.permissions = [];
    state.name = '';
    state.username = '';
    state.role = {};
  }
};
const ROLE_MAP = {
  1: 'admin',
  2: 'service',
  3: 'business'
};
const actions = {
  getInfo({
    commit
  }) {
    return new Promise((resolve, reject) => {
      (0, _auth2.authInfo)().then(response => {
        const {
          data
        } = response;
        commit('SET_NAME', data.name || data.username);
        commit('SET_ENNAME', data.en_name || data.username);
        commit('SET_USERNAME', data.username);
        const {
          username
        } = data;
        commit('SET_PERMISSIONS', [{
          code: ROLE_MAP[data.role.id]
        }]);
        resolve({
          username,
          permissions: [{
            code: ROLE_MAP[data.role.id]
          }]
        });
      }).catch(error => {
        reject(error);
      });
    });
  },
  login({
    commit
  }, userInfo) {
    return new Promise((resolve, reject) => {
      (0, _auth2.authLogin)(userInfo).then(response => {
        const {
          data
        } = response;
        commit('SET_TOKEN', data.access_token);
        (0, _auth.setToken)(data.access_token);
        resolve(data);
      }).catch(error => {
        reject(error);
      });
    });
  },
  // user logout
  logout({
    commit
  }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '');
      commit('SET_PERMISSIONS', []);
      (0, _auth.removeToken)();
      (0, _router.resetRouter)();
      resolve();
    });
  },
  // remove token
  resetToken({
    commit
  }) {
    return new Promise(resolve => {
      (0, _auth.removeToken)();
      commit('RESET_STATE');
      resolve();
    });
  }
};
var _default = exports.default = {
  namespaced: true,
  state,
  mutations,
  actions
};