"use strict";

var _interopRequireDefault = require("/root/workspace/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getPageTitle;
var _settings = _interopRequireDefault(require("@/settings"));
const title = _settings.default.title || 'Vue Admin Template';
function getPageTitle(pageTitle) {
  if (pageTitle) {
    return `${pageTitle} - ${title}`;
  }
  return `${title}`;
}