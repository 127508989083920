"use strict";

var _interopRequireDefault = require("/root/workspace/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _mixins = _interopRequireDefault(require("@/utils/mixins"));
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _common = require("@/api/common");
var _EditDialog = _interopRequireDefault(require("../templates/EditDialog.vue"));
var _default = exports.default = {
  components: {
    pagination: _index.default,
    EditDialog: _EditDialog.default
  },
  mixins: [_mixins.default.list],
  data() {
    return {
      endpoint: '/addr_parse_url/',
      statusOptions: [{
        value: 0,
        label: this.$t('address.status.0')
      }, {
        value: 1,
        label: this.$t('address.status.1')
      }],
      dialogVisible: false
    };
  },
  created() {
    this.loadList();
  },
  methods: {
    async loadList() {
      this.listloading = true;
      const requestData = {
        ...this.query
      };
      const res = await (0, _common.getList)(this.endpoint, requestData);
      const {
        data
      } = res;
      this.dataList = data.items;
      this.meta.total = data.total;
      this.meta.current_page = data.page;
      this.listloading = false;
    },
    async submit(row) {
      const res = await (0, _common.updateItem)(this.endpoint, row.id, row);
      if (res.code === 0) {
        this.$message({
          message: this.$t('common.operation_success'),
          type: 'success'
        });
        this.loadList();
      }
    },
    canConfirm(form) {
      return form.target != null && form.target.length > 0 && form.province != null && form.province.length > 0 && form.city != null && form.city.length > 0 && form.district != null && form.district.length > 0;
    }
  }
};